import React, {useState} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import styled from 'styled-components'
import MlaiCreateMain from './MlaiCreateMain'
import MlaiCreateByScan from './MlaiCreateByScan'

import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { useSelector } from 'react-redux'
import { ContextMlai } from '../Context/MlaiContext'
import { useParams } from 'react-router-dom'
import { useKnisaConfig } from '../../knisot/Create/config'

const Index = () => {
  const [startData, setStartData] = useState<string>('')

  const { tranzit, rights } = useSelector((state: RootState) => state.mainReducer.user) || {}
  const isCustomer = rights?.includes('CUSTOMER')
  const config = useKnisaConfig()

  const {id} = useParams<{id: string}>()
 
  const {
    data: warehouseList
  } = useDataFetcher<IWarhouseListItem[]>(
    Defaults.getWarhouseList, '2'
  )

  return (
    <ContextMlai.Provider value={{
      date: startData,
      setDate: setStartData,
      tranzit: tranzit || '',
      rights: rights || [''],
      isCustomer: !!isCustomer,
      warehouseList: warehouseList || [],
      defaultWarehouse: config.create.title.tranzit[1].value as number
    }} >

    <OrderlikeLayout
      label='ספירה חדשה'
    >
      <Container>
        <>
        
          {id === 'draft' ? (
            <MlaiCreateMain />
          ) : (
            <MlaiCreateByScan />
          )}
        </>
      </Container>
    </OrderlikeLayout>
    </ContextMlai.Provider>

  )
}

export default Index

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
