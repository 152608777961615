import React, { useEffect, useState, useMemo } from 'react'
import OrderlikeLayout from '../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import DatePickerModule from '../../../../generic/datePicker/datePicker'
import SearchInput from '../../../../generic/inputs/inputSearch';
import { Mlai } from '../../../../modules/API/Mlai/mlai';
import { ITitleMlaiItem } from '../../../../modules/API/Mlai/models/title';
import BodyScrollContainer from '../../../../generic/Layouts/Wrappers/BodyScrollContainer';
import TitlesList from '../../../../generic/titlesRowItem/TitlesList';
import MlaiTitleItem from './Components/MlaiTitleItem';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { LINKS } from '../../../../Routs/config';
import MlaiEditMain from './Edit/MlaiEditMain';
import ControlButton from '../../../../generic/buttons/ControlButton';
import { useDispatch } from 'react-redux';
import { LOAD, LOAD_END } from '../../../../../store/actions/actionsTypes';
import Index from './Create/Index';
import ButtonLayout from '../knisot/Layouts/ButtonLayout';

export default function MlaiSectionMain() {
    const {path} = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const [mlaiList, setMlaiList] = useState<ITitleMlaiItem[]>([]); 
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [inputSearchMlai, setInputSearchMlai] = useState('');

    //DateFrom=123123&DateTo=123123
    
    const getMlayList = async () => {
        dispatch({type: LOAD})
        try {
           let res: ITitleMlaiItem[] = await Mlai.getMlaiList(`DateFrom=${startDate}&DateTo=${endDate}`)
            setMlaiList(res)
        } catch (error) {
             
        } finally {
            dispatch({type: LOAD_END})
        }
    }

    const handleDeleteTitle = async (id: number) => {
        try {
            await Mlai.deleteTitleItem(id)
            const newArr = mlaiList.filter(item => item.CodeStock !== id)
            setMlaiList(newArr)
        } catch (error) {
            
        }
    }
    const render = useMemo(() => {
        if(!mlaiList) return []
        return mlaiList.filter(item => 
            item.Remarks.toString().includes(inputSearchMlai) ||
                item.DateStock.trim().includes(inputSearchMlai)
        )
    }, [mlaiList, inputSearchMlai])

    const handleSelectForEdit = (id: number) => {
        history.push(`${LINKS.mlai.mlai.editByID}${id}`)
    }
    const handleCreateNewMlai = (id: string) => {
        history.push(LINKS.mlai.mlai.mainURL + `/create/${id}`)
    }

    const titleProps = {
        handleSelectForEdit: handleSelectForEdit,
        handleDelete: handleDeleteTitle
    }
    useEffect(() => {
        if(!startDate || !endDate) return
        getMlayList()
    }, [startDate, endDate])

    return (
        <Switch>
        <Route path={path} exact>
            <OrderlikeLayout label='ספירות מלאי' >
                <ControlSearchPannelLayout>
                    <DatePickerModule 
                        multyDate
                        setSelectedDateStart={setStartDate}
                        defEndDate={false}
                        setSelectedDateEnd={setEndDate}
                        selected={false}
                        ignorBrod={true}
                        disabled={false}
                        large
                    />
                    <SearchInput 
                        input={inputSearchMlai}
                        setInput={setInputSearchMlai}
                    />
                </ControlSearchPannelLayout>
                <BodyScrollContainer>
                    <TitlesList 
                        list={render}
                        Element={MlaiTitleItem}
                        uniqueKey={'RecID'}
                        noPadding={false}
                        additionalData={titleProps}
                    />
                </BodyScrollContainer>
                <ButtonLayout>
                    <ControlButton
                        lable={' חדש לפי סריקה '}
                        handleClick={() => handleCreateNewMlai('scan')}
                    />
                    <ControlButton
                        lable={'חדש'}
                        handleClick={() => handleCreateNewMlai('draft')}
                    />

                </ButtonLayout>
            </OrderlikeLayout>
        </Route>
        <Route path={LINKS.mlai.mlai.create} component={Index} />
        <Route path={LINKS.mlai.mlai.editGeneral} component={MlaiEditMain} />
        </Switch>
    )
}
