import React, {useState, useMemo, useEffect, useContext} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { IPostProductsMlaiRequest, IProductMlaiForCreate } from '../../../../../modules/API/Mlai/models/productsForCreate'
import { Mlai } from '../../../../../modules/API/Mlai/mlai'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import ProductItem from './ProductItem'
import { useHistory } from 'react-router-dom'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import styled from 'styled-components'
import { LINKS } from '../../../../../Routs/config'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import DropdownByIcon from '../../azmanotRehesh/Layouts/DropdownByIcon'
import { WarehouseIcon } from '../../../../../generic/icons/Icons'
import WarehouseFilter from './WarehouseFilter'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import { Gathering } from '../../../../../modules/API/Gathering/gathering'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { handleScan } from '../utils/scanHandler'
import FlexColumnLayout from '../Layouts/FlexColumnLayout'
import { ContextMlai } from '../Context/MlaiContext'



const MlaiCreateMain = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    date,
    setDate,
    tranzit,
    isCustomer,
    warehouseList,
    defaultWarehouse
  } = useContext(ContextMlai)

  const [modal, setModal] = useContext(ContextModal)
  const [productsList, setProductsList] = useState<IProductMlaiForCreate[]>([])
  const [selectedTranzit, setSelectedTranzit] = useState<IWarhouseListItem | null>(null)

  const [input, setInput] = useState<string>('')

  
  const getProducts = async (tranzitID: number | string) => {
    console.log(tranzitID)
    dispatch({type: LOAD})
    try {
      const res = await Mlai.getProductsForCreate(tranzitID.toString())
      setProductsList(addIDForTheList(res))
    } catch (error) {
      
    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const postProducts = async () => {
    if(!productsList) return
    dispatch({type: LOAD})
    try {
      const dataPost: IPostProductsMlaiRequest = {
        DateStock: date,
        TranzitID: isCustomer ? Number(tranzit || 0) : Number(selectedTranzit?.WarehouseID || 0),
        Details: productsList.filter(line => line.isEdited && (line?.tempQty || 0) > 0).map(item => {
          let {id, isEdited, tempQty, tempWeight, ...rest} = item
          return {
            ...rest,
            NumPackagesActulal: tempQty || 0,
            WeightActual: tempWeight || 0
          }
        })
      }
      await Mlai.postProducts(dataPost)
      history.push(LINKS.mlai.mlai.mainURL)
    } catch (error) {
      handleModal(
        false,
        `שגיאה בשמירת המילאי`
      )
    } finally {
      dispatch({type: LOAD_END})
    }
  }
  const handleChangeQty = (qty: number, weightPerPack: number, index: number) => {
    if(!productsList) return
    const newList = [...productsList]
    newList[index].tempQty = qty
    newList[index].tempWeight = qty * weightPerPack
    newList[index].isEdited = true
    setProductsList(newList)
  }

  const handleModal = (mood: boolean, message: string) => {
    setModal({
      open: true,
      value: message,
      mood: mood
    })
    setTimeout(() => {
      setModal({
          ...modal,
          open: false
      })
    }, 3000)
  }

  // const handleScanFunc = async () => {
  //    handleScan({
  //     selectedTranzit,
  //     productsList,
  //     setProductsList,
  //     input,
  //     setInput,
  //     handleModal}
  //   )
  // }

  const renderProducts = useMemo(() => {
    if(!productsList) return []
    return productsList.filter(item => 
      `${item.ProductDesc.trim()} ${item.VarietyDesc.trim()}`.includes(input)
    )
  }, [productsList, input])
  console.log(productsList)
  const productProps = {
    handleChangeQty: handleChangeQty
  }
  console.log(defaultWarehouse)

  useEffect(() => {
    if(isCustomer ? !tranzit : !selectedTranzit  ) return setProductsList([])
    const tranzitID = isCustomer ? (tranzit || 0) : (selectedTranzit?.WarehouseID || 0)
    getProducts(tranzitID || defaultWarehouse)
  }, [tranzit, selectedTranzit, defaultWarehouse])

  return (
    <FlexColumnLayout>
 
      <ControlSearchPannelLayout>
     
        <DatePickerModule
          setSelectedDateStart={setDate}
          setSelectedDateEnd={false}
          defEndDate={false}
          selected={false}
          ignorBrod={true}
          disabled={false}
          large
        />
        <SearchInput 
          input={input}
          setInput={setInput}
          // enterFunc={handleScanFunc}
        />
        {
          !isCustomer &&
            <WarehouseFilter
              defaultID={defaultWarehouse}
              list={warehouseList || []}
              selectedWarehouse={selectedTranzit}
              setSelectedWarehouse={setSelectedTranzit}
            />
            // <DropdownByIcon
            //   isOreintaionRight
            //           icon={<WarehouseIcon />}
            //           // isSelected={priceFilterMihira !== 0 || priceFilterKniya !== 0}
            //           children={
            //             <WarehouseFilter
            //               list={warehouseList || []}
            //               selectedWarehouse={selectedTranzit}
            //               setSelectedWarehouse={setSelectedTranzit}
            //             />
            //           }
            //       />
        }
      </ControlSearchPannelLayout>
      <BodyScrollContainer>
        <TitlesList 
          list={renderProducts}
          uniqueKey={'id'}
          noPadding={false}
          Element={ProductItem}
          additionalData={productProps}
        />
      </BodyScrollContainer>
        <ControlWrapper>
          <ControlButton
            lable={'ביטול'}
            handleClick={() => history.goBack()}
          />
          <ControlButton 
            lable={'שמירה'}
            handleClick={postProducts}
          />
        </ControlWrapper>
    </FlexColumnLayout>
  )
}
export default MlaiCreateMain

const ControlWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 0.5em;
`