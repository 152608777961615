import React, { useContext, useMemo, useRef, useState } from 'react'
import FlexColumnLayout from '../Layouts/FlexColumnLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import { ContextMlai } from '../Context/MlaiContext'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { IPostProductsMlaiRequest, IProductMlaiForCreate } from '../../../../../modules/API/Mlai/models/productsForCreate'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import WarehouseFilter from './WarehouseFilter'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import ProductItem from './ProductItem'
import ControlWrapper from '../Layouts/ControlWrapper'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { useHistory } from 'react-router-dom'
import { handleScan } from '../utils/scanHandler'
import { useDispatch } from 'react-redux'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import { Mlai } from '../../../../../modules/API/Mlai/mlai'
import { LINKS } from '../../../../../Routs/config'
import useScannedDataHandler from '../../kosherTeudot/Hooks/useScannedDataHandler'
import ScanInput from '../../kosherTeudot/Components/ScanInput'

interface IRef {
  focusInput: () => void;
  blurInput: () => void;

}
const MlaiCreateByScan = () => {
  const history = useHistory()
  const {
    date,
    setDate,
    tranzit,
    isCustomer,
    warehouseList,
    defaultWarehouse
  } = useContext(ContextMlai)

  const dispatch = useDispatch()
  const refBody = useRef<IRef | null>(null);

  const [modal, setModal] = useContext(ContextModal)
  const [productsList, setProductsList] = useState<IProductMlaiForCreate[]>([])
  const [selectedTranzit, setSelectedTranzit] = useState<IWarhouseListItem | null>(null)

  const [input, setInput] = useState<string>('')
  const handleModal = (mood: boolean, message: string) => {
    setModal({
      open: true,
      value: message,
      mood: mood
    })
    setTimeout(() => {
      setModal({
        ...modal,
        open: false
      })
    }, 3000)
  }

  const handleScanFunc = async () => {
    handleScan({
      selectedTranzit,
      productsList,
      setProductsList,
      input,
      setInput,
      handleModal
    }
    )
  }

  const handleChangeQty = (qty: number, weightPerPack: number, index: number) => {
    if(!productsList) return
    const newList = [...productsList]
    newList[index].tempQty = qty
    newList[index].tempWeight = qty * weightPerPack
    newList[index].isEdited = true
    setProductsList(newList)
  }

  const postProducts = async () => {
    if(!productsList) return
    dispatch({type: LOAD})
    try {
      const dataPost: IPostProductsMlaiRequest = {
        DateStock: date,
        TranzitID: isCustomer ? Number(tranzit || 0) : Number(selectedTranzit?.WarehouseID || 0),
        Details: productsList.filter(line => line.isEdited && (line?.tempQty || 0) > 0).map(item => {
          let {id, isEdited, tempQty, tempWeight, ...rest} = item
          return {
            ...rest,
            NumPackagesActulal: tempQty || 0,
            WeightActual: tempWeight || 0
          }
        })
      }
      await Mlai.postProducts(dataPost)
      history.push(LINKS.mlai.mlai.mainURL)
      handleModal(true, `המילאי נוצר בהצלחה`)
    } catch (error) {
      
    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const renderProducts = useMemo(() => {
    if (!productsList) return []
    return productsList.filter(item =>
      `${item.ProductDesc.trim()} ${item.VarietyDesc.trim()}`.includes(input)
      ||
      item.GrowerName?.trim().includes(input)

    )
  }, [productsList, input])
  const productProps = {
    handleChangeQty: handleChangeQty

  }
  useScannedDataHandler(refBody, handleScanFunc)
  return (
    <FlexColumnLayout>
      {/* <div
        onClick={() => refBody.current?.focusInput()}
      >
        asd
      </div> */}
      <ControlSearchPannelLayout>
        <DatePickerModule
          setSelectedDateStart={setDate}
          setSelectedDateEnd={false}
          defEndDate={false}
          selected={false}
          ignorBrod={true}
          disabled={false}
          large
        />
        <ScanInput
          input={input}
          setInput={setInput}
          enterFunc={handleScanFunc}
          ref={refBody}

        />
        {
          !isCustomer &&
          <WarehouseFilter
            list={warehouseList || []}
            selectedWarehouse={selectedTranzit}
            setSelectedWarehouse={setSelectedTranzit}
            defaultID={defaultWarehouse}
          />
          // <DropdownByIcon
          //   isOreintaionRight
          //           icon={<WarehouseIcon />}
          //           // isSelected={priceFilterMihira !== 0 || priceFilterKniya !== 0}
          //           children={
          //             <WarehouseFilter
          //               list={warehouseList || []}
          //               selectedWarehouse={selectedTranzit}
          //               setSelectedWarehouse={setSelectedTranzit}
          //             />
          //           }
          //       />
        }
      </ControlSearchPannelLayout>
      <BodyScrollContainer>
        <TitlesList
          list={renderProducts}
          uniqueKey={'id'}
          noPadding={false}
          Element={ProductItem}
          additionalData={productProps}
        />
      </BodyScrollContainer>
      <ControlWrapper>
        <ControlButton
          lable={'ביטול'}
          handleClick={() => history.goBack()}
        />
        <ControlButton
          lable={'שמירה'}
          handleClick={postProducts}
        />
      </ControlWrapper>
    </FlexColumnLayout>
  )
}

export default MlaiCreateByScan