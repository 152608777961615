import React, {useEffect, useMemo, useState} from 'react'
import { IPrintersItem } from '../../../modules/API/Defaults/models/printers'
import SearchFromTheList from '../../customSearchComponents/SearchFromTheList'




// AddressWeight
// : 
// "http://62.90.247.237:80/w2.txt                    "
// DescWeights
// : 
// "משקל 2              "
// InnerPort
// : 
// "                    "
// NumWeight
// : 
// 2
// TypeDevice
// : 
// 1

type Props = {
  list: IPrintersItem[]
  defaultID: string,
  setScaleAdress: (url: string) => void,
  required?: boolean,
  placeholder?: string
}

const SearchScales = ({
  list, defaultID, setScaleAdress, placeholder, required
}: Props) => {
  console.log(defaultID)
  const defaultScale = list.filter(item => item.AddressWeight.trim() === defaultID.trim())[0]
  console.log(defaultScale?.AddressWeight?.trim() === defaultID.trim())
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedScale, setSelectedScale] = useState<IPrintersItem | null>(defaultScale || null)
  const [search, setSearch] = useState<string>(defaultScale?.DescWeights?.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedScale(null)
      setScaleAdress('')
      setIsOpenDropDown(false)
      setSearch('')
      return 
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IPrintersItem) => {
    setScaleAdress(item.AddressWeight.trim())
    setSearch(item.DescWeights.trim())
    setSelectedScale(item)
    setIsOpenDropDown(false)
  }

  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.DescWeights?.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [list, search])

  useEffect(() => {
    if (!defaultScale) return
    console.log('run')
    setSearch(defaultScale.DescWeights.trim())
  }, [defaultScale])

  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={placeholder || 'חפש משקל'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='DescWeights'
      nameIdElement='NumWeight'
      required={required}
      size={'full'}
    
    />
  )
}

export default SearchScales