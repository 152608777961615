import { v4 } from 'uuid'
import { IWarhouseListItem } from "../../../../../modules/API/Defaults/models/warhouseList";
import { Gathering } from "../../../../../modules/API/Gathering/gathering";
import { IProductMlaiForCreate } from "../../../../../modules/API/Mlai/models/productsForCreate";
interface IScanHandler {
  selectedTranzit: number | string | null | undefined | IWarhouseListItem
  productsList: IProductMlaiForCreate[]
  setProductsList: React.Dispatch<React.SetStateAction<IProductMlaiForCreate[]>>
  input: string
  setInput: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void)
  handleModal: (mood: boolean, message: string) => void
}

export const handleScan = async ({
  selectedTranzit,
  productsList,
  setProductsList,
  input,
  setInput,
  handleModal
}: IScanHandler): Promise<void> => {
  if (!selectedTranzit) {
    handleModal(false, `אנא בחר מחסן`);
    return
  }
  // Check if input has something other than numbers (English and Hebrew)
  if (input.match(/[a-z\u0590-\u05FF]/i)) return;
  try {
    const res = await Gathering.getKnisaByBarcode(input);
    
    const findInTheList = productsList.find(
      item => item.ProductID === res.entryLine[0].ProductID &&
              item.VarietyID === res.entryLine[0].VarietyID &&
              item.GrowerID === res.entryLine[0].GrowerID &&
              item.SizeID === res.entryLine[0].SizeDesc &&
              item.PackingID === res.entryLine[0].PackID
              
              
    );
    
    if (findInTheList) {
      setInput(`${findInTheList.ProductDesc.trim()} ${findInTheList.VarietyDesc.trim()}`);
    } else {
      const newProduct: IProductMlaiForCreate = {
        GeneralProductID: 0,
        NumPackagesActulal: res.entryLine[0].BalanceAmount,
        ProductDesc: res.entryLine[0].ProductDesc,
        ProductID: res.entryLine[0].ProductID,
        VarietyDesc: res.entryLine[0].VarietyDesc,
        VarietyID: res.entryLine[0].VarietyID,
        WeightActual: res.entryLine[0].Gross,
        GrowerID: res.entryLine[0].GrowerID,
        GrowerName: res.entryLine[0].GrowerName,
        SizeID: res.entryLine[0].SizeDesc,
        PackingID: res.entryLine[0].PackID,
        tempQty: 0,
        tempWeight: 0,
        isEdited: false,
        Price: 0,
        id: v4()
      };
      setProductsList([newProduct, ...productsList]);
      setInput(`${newProduct.ProductDesc.trim()} ${newProduct.VarietyDesc.trim()}`);
      handleModal(true, `פריט נוסף לרשימה`);
    }
  } catch (error) {
    handleModal(false, `שגיאה במהלך הסריקה`);
  }
};


