import { IPalletTypeItem } from '../../../modules/API/Defaults/models/pallets'
import { IPalletToChangeItem } from '../../../modules/API/Pallets/models/palletToChange'
import SearchBlockWithTitle from '../../../main/second/mainbar/procedures/Q8/Layouts/SearchBlockWithTitle'
import SearchPalletType from '../../../main/second/mainbar/knisot/Create/Components/SearchPalletType'
import CircleNumericInput from '../../inputs/CircleNumericInput'
import CircleActiveFilterBtn from '../../particles/CircleActiveFilterBtn'
import { CloseIcon, PackageIcon, PalletIcon } from '../../icons/Icons'

interface Props extends IPalletToChangeItem {
  palletTypeList: IPalletTypeItem[],
  onChangeType: (index: number, type: number) => void
  onChangeQty: (index: number, qty: number) => void
  onClear: (index: number) => void
  index: number
}

const PalletToChangeItem = ({
  palletTypeList,
  Pallet_Num,
  Pallet_Type,
  Pallete_Desc,
  Quantity_Pallets,
  Real_Pallete,
  RecID,
  onChangeType,
  onChangeQty,
  index,
  onClear,
  Quantity
}: Props) => {

  const handleTypeChange = (type: number) => {
    onChangeType(index, type)
  }

  const handleQtyChange = (qty: number) => {
    onChangeQty(index, +qty)
  }

  const handleClear = () => {
    onClear(index)
  }

  return (
    <SearchBlockWithTitle
      title={
        <>
          ( {Quantity} - <PackageIcon /> ) #{Pallet_Num} <PalletIcon />
        </>
      }
    >
      <CircleNumericInput 
        value={Quantity_Pallets}
        changeFunc={handleQtyChange}
      />
      <SearchPalletType 
        defaultID={Pallet_Type}
        list={palletTypeList}
        setPalletID={handleTypeChange}
      />
      {
        Real_Pallete !== 0 && (
        <CircleActiveFilterBtn
          func={handleClear}
        >
          <CloseIcon handler={() => {}} />
        </CircleActiveFilterBtn>

        )
      }
    </SearchBlockWithTitle>
  )
}

export default PalletToChangeItem