import { AnyAction } from "redux"

export interface IPrinterData {
    printerID: number | null
    formatID: number | null
    scales: string | null
    isOpen: boolean
}

const initialState: IPrinterData = {
    printerID: null,
    formatID: null,
    isOpen: false,
    scales: ''
}

export const SET_PRINTER_ID = 'SET_PRINTER_ID'
export const SET_FORMAT_ID = 'SET_FORMAT_ID'
export const SET_IS_OPEN = 'SET_IS_OPEN'
export const CLEAR_PRINTER_STATE = 'CLEAR_PRINTER_STATE'
export const SET_SCALES = 'SET_SCALES'

const printerReducer = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SET_PRINTER_ID:
            return {
                ...state,
                printerID: action.payload
            }

        case SET_FORMAT_ID:
            return {
                ...state,
                formatID: action.payload
            }
            
        case SET_SCALES:
            return {
                ...state,
                scales: action.payload
            }

        case SET_IS_OPEN:
            return {
                ...state,
                isOpen: action.payload
            }

        case CLEAR_PRINTER_STATE:
            return initialState
              
        default: return state
    }
}

export default printerReducer